import React from 'react'
import PropTypes from 'prop-types'
import { graphql, Link } from 'gatsby'
import Img from 'gatsby-image'
import * as Yup from 'yup';
import Layout from '../components/Layout'
import ContactForm from '../components/ContactForm/ContactForm'
import SEO from '../components/SEO'
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from 'react-google-maps'

const requiredError = 'Please fill in the required field.';
const _formData = {
  formName: 'acscotland-bookademo',
  recaptcha: {
    display: true,
    theme: 'light'
  },
  privacyPolicy: {
    display: true
  },
  validationSchema: Yup.object().shape({
    Name: Yup.string()
      .required(requiredError),
    Company: Yup.string()
      .required(requiredError),
    Email: Yup.string()
      .email()
      .required(requiredError),
    Telephone: Yup.string()
      .required(requiredError),
    Address1: Yup.string()
      .required(requiredError),
    Address2: Yup.string(),
    TownCity: Yup.string()
      .required(requiredError),
    County: Yup.string()
      .required(requiredError),
    Postcode: Yup.string()
      .required(requiredError),
    recaptcha: Yup.string()
      .required('Recaptcha response is required.'),
    privacyPolicy: Yup.boolean()
      .oneOf([true], 'Privacy Policy agreement is required.')
      .required('Privacy Policy agreement is required.')
  }),
  formFields: [
    {
      name: 'Name',
      label: 'Name',
      placeholder: 'Your name',
      type: 'text'
    },
    {
      name: 'Company',
      label: 'Company',
      placeholder: 'Your company',
      type: 'text'
    },
    {
      name: 'Email',
      label: 'Email Address',
      placeholder: 'Your email',
      type: 'text'
    },
    {
      name: 'Telephone',
      label: 'Telephone',
      placeholder: 'Your telephone',
      type: 'text'
    },
    {
      name: 'Address1',
      label: 'Address 1',
      placeholder: 'Address 1',
      type: 'text'
    },
    {
      name: 'Address2',
      label: 'Address 2',
      placeholder: 'Address 2',
      type: 'text'
    },
    {
      name: 'TownCity',
      label: 'Town/City',
      placeholder: 'Town/City',
      type: 'text'
    },
    {
      name: 'County',
      label: 'County',
      placeholder: 'County',
      type: 'text'
    },
    {
      name: 'Postcode',
      label: 'Postcode',
      placeholder: 'Postcode',
      type: 'text'
    }
  ],
  initialValues: {
    Name: '',
    Company: '',
    Email: '',
    Telephone: '',
    Address1: '',
    Address2: '',
    TownCity: '',
    County: '',
    Postcode: ''
  }
};

export const PageTemplate = ({ title, content, images }) => {
  return (
    <section className="page-wrapper section">
      <div className="container">
        <div className='columns'>
          <div className="column is-6 is-offset-1">
            <h2 className="title is-size-3 has-text-weight-bold is-bold-light">
              {title}
          </h2>
            <div
              className="content"
              dangerouslySetInnerHTML={{ __html: content }}
            />
            <ContactForm formData={_formData} />
          </div>
          <div className='column is-4'>
            <Img fluid={images.access1.childImageSharp.fluid} style={{ margin: '0 0 1rem 0' }}/>
            <Img fluid={images.access3.childImageSharp.fluid} />
          </div>
        </div>
      </div>
    </section>
  )
}

PageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
}

const Page = ({ data }) => {
  const { wordpressPage: page } = data
  const images = {
    access1: data.accessImage1,
    access3: data.accessImage3
  };

  return (
    <Layout>
      <SEO
        title={page.title}
      />
      <PageTemplate
        title={page.title}
        content={page.content}
        images={images}
      />
    </Layout>
  )
}

Page.propTypes = {
  data: PropTypes.object.isRequired,
}

export default Page

export const pageQuery = graphql`
  query BookADemoPageById($id: String!) {
    wordpressPage(id: { eq: $id }) {
      title
      content
      slug
    }
    accessImage1: file(relativePath: { eq: "access.web_.1.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 430) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    accessImage3: file(relativePath: { eq: "access.web_.3.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 430) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
